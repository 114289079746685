$(document).ready(function () {

    //для работы внутристраничного меню, когда отключен плагин fullPage
    if (window.innerWidth<668){
        $('.section:nth-of-type(1)').attr('id','home');
        $('.section:nth-of-type(2)').attr('id','about-us');
        $('.section:nth-of-type(3)').attr('id','projects');
        $('.section:nth-of-type(4)').attr('id','partners');
        $('.section:nth-of-type(5)').attr('id','products');
        $('.section:nth-of-type(6)').attr('id','catalogue');
        $('.section:nth-of-type(7)').attr('id','config');
        $('.section:nth-of-type(8)').attr('id','techn');
        $('.section:nth-of-type(9)').attr('id','method');
        $('.section:nth-of-type(10)').attr('id','news');
        $('.section:nth-of-type(11)').attr('id','rights');
        $('.section:nth-of-type(12)').attr('id','contacts');

        $("#burger-main-page-nav a").mPageScroll2id();

    }

    //чтобы открыть всплывающее окно с формой или с подтверждением отправки письма, 
    //достаточно соответсвующему id добавить (удалить) класс open-popup
    $("#open-popup-form").click(function(){
        $("#form-popup").addClass("open-popup");
        $("body").addClass("ovh"); // ovh=>overflow: hidden
    });
    $(".layer-popup .close-but").click(function(){
        $(".layer-popup").removeClass("open-popup");
        $("body").removeClass("ovh"); // ovh=>overflow: hidden
    });

    //открыть (закрыть) бургер-меню
    $('.main-scr-burg-block, a.burger-item-style, .burger-main-page-nav a').click(function(){
        var mainHeader=$('body');
        if (!(mainHeader.hasClass('open-burger'))){
            mainHeader.addClass('open-burger');
            if (window.innerWidth>667){
                $.fn.fullpage.setAllowScrolling(false);
            }
        } else{
            mainHeader.removeClass('open-burger');
            if (window.innerWidth>667){
                $.fn.fullpage.setAllowScrolling(true);
            }
        }
    });

    //работа бокового, которые присутствую на некоторых страницах
    $('.side-nav-item').click(function(){
        $('.side-nav-item').removeClass('active');
        $(this).addClass('active');
    });
    $('.second-side-mob').click(function(){
        $(this).closest('.second-left-content').toggleClass('active');
    });


    //для фотогаллереи на странице описания товара
    $('.popup-img-link').magnificPopup({
      type: 'image',
      mainClass: 'mfp-no-margins mfp-with-zoom',
      zoom: {
            enabled: true,
            duration: 300 // don't foget to change the duration also in CSS
        }
      // other options
    });


    //Слайдеры
    $('.slick1').slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive:[
            {
                breakpoint: 620,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 426,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    $('.page-img-block').slick({
        infinite: true,
        slidesToShow: 1,
        arrows: false,
        fade: true,
        asNavFor: '.flex-img'
    });
    $('.flex-img').slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        asNavFor: '.page-img-block',
        focusOnSelect: true
        // responsive:[
        //     {
        //         breakpoint: 665,
        //         settings: {
        //             slidesToShow: 2
        //         }
        //     }
            // {
            //     breakpoint: 668,
            //     settings: "unslick"
            // }
        // ]
    });
});


$(window).load(function() {

    for(var i = 1; i <= 100; i++) {
        (function(index) {
            setTimeout(function() { 
                $('.loader-line').css('background-size', index + '%' + ' 100% ');
            }, 10*i);
        })(i);
    }


    $(".loader").delay(1200).fadeOut("slow");
    // $(".rect1").delay(1200).slideDown("slow");
    // $(".rect2").delay(1300).slideDown("slow");
    // $(".rect3").delay(1400).slideDown("slow");
    
    //анимация при скролле, когда не работает плагин fullPage
    if (window.innerWidth<668){
        $("h4:not(.untransformed), .main-h2, .animation1, h3, p, a:not(.sub-nav-item, .burger-item-style, .catalog-prod-item-but), figure, .full-article-block img, span:not(.loader-line, .img-text, .untransformed, .burg-line), .main-footer, input, .form-block, .team-member").animated('fadein_moveup_60');
    }

    $(".bgline1").animated('height-anim');
    $(".bgline2").animated('height-anim');
    $(".bgline3").animated('height-anim');

}); 

    (function($) {
        $.fn.animated = function(inEffect) {
                $(this).css("opacity", "0").addClass("animated").waypoint(function() {
                                $(this).addClass(inEffect).css("opacity", "1");

                }, {
                        offset: "99%"
                });
        };
    })(jQuery);